import classNames from 'classnames';
import React from 'react';

interface IFloatingActionButtonProps {
  icon: string;
  items: Array<{
    onClick: () => void;
    icon: string;
    color?: 'primary' | 'danger' | 'success';
  }>;
}

const FloatingActionButton = ({ icon, items = [] }: IFloatingActionButtonProps) => {
  return (
    <div className="fab-container">
      <div className="fab shadow">
        <div className="fab-content">
          <i className={icon}></i>
        </div>
      </div>
      {items.map((item) => (
        <div
          key={item.icon}
          className={classNames('sub-button shadow', `sub-button__${item.color || 'primary'}`)}
          onClick={item.onClick}
        >
          <i className={item.icon}></i>
        </div>
      ))}
    </div>
  );
};

export default FloatingActionButton;
