import React from 'react';
import { Modal } from 'reactstrap';
import CreateContent from './create';
import CloseContent from './close';

interface IActionModal {
  router: any;
  type: 'create' | 'close' | undefined;
  handleModal: (type: 'create' | 'close' | undefined) => void;
}

const ActionModal = ({ router, type, handleModal }: IActionModal) => {
  const contents = {
    create: () => <CreateContent router={router} handleModal={handleModal} />,
    close: () => <CloseContent handleModal={handleModal} />,
  };

  if (!type) return null;

  const Component: any = contents[type];

  return (
    <Modal isOpen={!!type} toggle={() => handleModal(undefined)}>
      <Component />
    </Modal>
  );
};

export default ActionModal;
