import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import TableContainer from '@common/NewTableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/DailyOperations/components/Table/constants';
import { getAll } from '@modules/DailyOperations/services';
import FloatingActionButton from '@component/common/FloatingActionButton';
import ActionModal from '../components/ActionModal';
import TRow from '../components/TRow';

const DailyOperations = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<string>('');
  const [modal, setModal] = useState<'create' | 'close' | undefined>();

  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getAllDailyOperations', page, size, filters],
    queryFn: () => getAll({ page, limit: size, filters }),
    initialData: {
      page: {
        limit: 10,
        page: 1,
        totalRows: 0,
        totalPages: 0,
      },
      items: [],
    },
    retry: 1,
  });

  useEffect(() => {
    if (!modal) {
      refetch();
    }
  }, [modal]);

  return (
    <Page
      title="Operações diárias"
      breadcrumb={{
        title: 'Operações diárias',
        titleHref: '/daily-operations',
        breadcrumbItem: 'Listar operações diárias',
      }}
    >
      <Row>
        <Col lg="12">
          <ActionModal router={router} type={modal} handleModal={setModal} />
          <Card>
            <CardBody>
              <TableContainer
                moduleName="daily-operations"
                columns={buildColumns()}
                currentPage={page}
                customPageSize={size}
                data={data.items}
                loading={isFetching}
                totalItems={data.page.totalPages}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                customRow={TRow}
                refetch={refetch}
                disableHover
                isPagination
                isShowingPageLength
              />
            </CardBody>
          </Card>
        </Col>

        {user.is_admin && (
          <FloatingActionButton
            icon="mdi mdi-menu"
            items={[
              {
                icon: 'mdi mdi-plus',
                onClick: () => setModal('create'),
                color: 'success',
              },
              {
                icon: 'mdi mdi-close',
                onClick: () => setModal('close'),
                color: 'danger',
              },
            ]}
          />
        )}
      </Row>
    </Page>
  );
};

export default withRouter(DailyOperations);
