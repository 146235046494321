import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { DailyOperationOpenForm } from '../DailyOperationOpenForm';
import { useMutation } from '@tanstack/react-query';
import { IDailyOperationOpen } from '@modules/DailyOperations/types';
import { open } from '@modules/DailyOperations/services';
import { useFormik } from 'formik';
import moment from 'moment';
import { TYPE_INITIAL_AMOUNT, TYPE_OPENED_AT } from '@modules/DailyOperations/constants';

const CreateContent = ({ router, handleModal }: any) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IDailyOperationOpen) => open(payload),
    onSuccess: () => {
      handleModal(false);
      validation.resetForm();
    },
  });

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      brokerAccount: {
        value: '',
        label: '',
      },
      initialAmount: 0,
      openedAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    },
    validationSchema: Yup.object({
      initialAmount: Yup.string().required(TYPE_INITIAL_AMOUNT),
      openedAt: Yup.string().required(TYPE_OPENED_AT),
    }),
    onSubmit: (payload: IDailyOperationOpen) => mutate(payload),
  });

  return (
    <>
      <ModalHeader>Adicionar operação</ModalHeader>
      <ModalBody>
        <DailyOperationOpenForm
          validation={validation}
          loading={isPending}
          registrationError={error?.message}
          showAlert={isSuccess}
        />
      </ModalBody>
    </>
  );
};

export default CreateContent;
