import React, { useState, useEffect } from 'react';

import { IExpense } from '@modules/Finance/types/transactions';
import { Repetition } from '@modules/Finance/ultis/transactions/enums';

import * as S from './styles';

import { ITransactionListProps } from './types';

export const TransactionList = ({ transactions }: ITransactionListProps) => {
  // * [States] * //
  const [paidTransactions, setPaidTransactions] = useState<string[]>([]);
  const [showDescription, setShowDescription] = useState<string[]>([]);
  const [showRepetition, setShowRepetition] = useState<string[]>([]);

  // * [Functions] * //
  const formatDate = (dateString: string): { month: string; day: string } => {
    const date = new Date(dateString);

    const month = date.toLocaleString('pt-BR', { month: 'short' });
    const day = date.toLocaleString('pt-BR', { day: '2-digit' });

    return { month, day };
  };

  const groupTransactionsByDate = (transactions: IExpense[]) => {
    return transactions?.reduce<Record<string, IExpense[]>>((acc, transaction) => {
      const dateKey = transaction?.paymentDate;

      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(transaction);
      return acc;
    }, {});
  };

  const formatToBRNumber = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handlePaymentToggle = (transactionId: string) => {
    setPaidTransactions((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  const handleDescriptionTooltip = (transactionId: string) => {
    setShowDescription((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  const handleRepetitionTooltip = (transactionId: string) => {
    setShowRepetition((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  // * [Render] * //
  const groupedTransactions = groupTransactionsByDate(transactions || []);

  // * [Effects] * //
  useEffect(() => {
    const initialPaidTransactions =
      transactions
        ?.filter((transaction) => transaction.conciliated)
        ?.map((transaction) => transaction.id) || [];

    setPaidTransactions(initialPaidTransactions);
  }, [transactions]);

  return (
    <S.TransactionListContainer>
      {Object.entries(groupedTransactions)?.map(([dateKey, transactionGroup]) => {
        const { month, day } = formatDate(dateKey);

        return (
          <React.Fragment key={dateKey}>
            <S.TransactionDateWrapper>
              <S.TransactionDay>{day}</S.TransactionDay>
              <S.TransactionMonth>{month}</S.TransactionMonth>
            </S.TransactionDateWrapper>

            {transactionGroup?.map((expense) => {
              const isPaid = paidTransactions.includes(expense.id);
              const paymentIcon = isPaid ? 'mdi mdi-thumb-up' : 'mdi mdi-thumb-down';

              return (
                <S.TransactionItem key={expense?.id}>
                  <S.CategoryWrapper>
                    <S.CategoryIcon
                      color={expense?.category?.color}
                      className={expense?.category?.icon}
                    />
                    <S.CategoryName>{expense?.category?.name}</S.CategoryName>
                    <S.RepeatCount>
                      {expense?.installments}/{expense?.installments}
                    </S.RepeatCount>
                  </S.CategoryWrapper>

                  <S.DetailsWrapper>
                    <S.DetailsIcon
                      id={`description-${expense?.id}`}
                      className="mdi mdi-message-text"
                    />
                    <S.DetailsTooltip
                      isOpen={showDescription.includes(expense?.id)}
                      target={`description-${expense?.id}`}
                      toggle={() => handleDescriptionTooltip(expense?.id)}
                    >
                      <S.DetailsText>{expense?.description}</S.DetailsText>
                    </S.DetailsTooltip>

                    {expense?.repetition === Repetition.Recurring && (
                      <>
                        <S.DetailsIcon
                          id={`repetition-${expense?.id}`}
                          className="mdi mdi-repeat"
                          title="Essa é uma transação fixa"
                        />
                        <S.DetailsTooltip
                          isOpen={showRepetition.includes(expense?.id)}
                          target={`repetition-${expense?.id}`}
                          toggle={() => handleRepetitionTooltip(expense?.id)}
                        >
                          <S.DetailsText>Essa é uma transação fixa</S.DetailsText>
                        </S.DetailsTooltip>
                      </>
                    )}
                  </S.DetailsWrapper>

                  <S.AccountName>
                    {expense?.wallet?.name}/{expense?.paymentMethod?.name}
                  </S.AccountName>

                  <S.TransactionValueWrapper>
                    <S.TransactionValue>-{formatToBRNumber(expense?.amount)}</S.TransactionValue>
                    <S.PaymentToggleIcon
                      onClick={() => handlePaymentToggle(expense?.id)}
                      className={paymentIcon}
                      isPaid={isPaid}
                    />
                  </S.TransactionValueWrapper>
                </S.TransactionItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </S.TransactionListContainer>
  );
};
