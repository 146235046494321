import { ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

// * [Modal Header] * //
export const ManageTransactionsModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;

// * [Modal Footer] * //
export const ManageTransactionsModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;

  padding-bottom: 32px;

  border-top: none;
`;
