import React from 'react';
import BrokerAccounts from '@modules/BrokerAccounts/pages';
import BrokerAccountsCreate from '@modules/BrokerAccounts/pages/create';
import BrokerAccountsUpdate from '@modules/BrokerAccounts/pages/update';

export const brokerAccountsRoutes = [
  { path: '/broker-accounts', component: <BrokerAccounts /> },
  { path: '/broker-accounts/create', component: <BrokerAccountsCreate /> },
  { path: '/broker-accounts/:brokerAccountId', component: <BrokerAccountsUpdate /> },
];
