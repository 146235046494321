import React, { useCallback, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';

import * as S from './styles';

import { ITransactionsFilterModalProps } from './types';
export const TransactionsFilterModal = ({
  showModal,
  toggle,
  title,
}: ITransactionsFilterModalProps) => {
  // * [States] * //
  const [openAccordion, setOpenAccordion] = useState<string>('');

  // * [Functions] * //
  const toggleModal = useCallback(() => {
    toggle();
  }, []);

  const toggleAccordion = (id: string) => {
    if (openAccordion === id) {
      setOpenAccordion('');
    } else {
      setOpenAccordion(id);
    }
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered>
      <S.TransactionsModalHeader toggle={toggleModal}>{title}</S.TransactionsModalHeader>

      <ModalBody>
        <S.FilterAccordion flush open={openAccordion} toggle={toggleAccordion}>
          <S.FilterAccordionItem>
            <S.FilterAccordionHeader targetId="1">Tipo</S.FilterAccordionHeader>
            <S.FilterAccordionBody accordionId="1">
              <Form>
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Despesas</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Receitas</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Transferências</Label>
                </FormGroup>
              </Form>
            </S.FilterAccordionBody>
          </S.FilterAccordionItem>

          <S.FilterAccordionItem>
            <S.FilterAccordionHeader targetId="2">Repetição</S.FilterAccordionHeader>
            <S.FilterAccordionBody accordionId="2">
              <Form>
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Únicos</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Parcelados</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input type="checkbox" />
                  <Label check>Fixos</Label>
                </FormGroup>
              </Form>
            </S.FilterAccordionBody>
          </S.FilterAccordionItem>
        </S.FilterAccordion>
      </ModalBody>

      <S.TransactionsModalFooter>
        <Button
          color="primary"
          onClick={() => {}}
          disabled={false}
          className="d-flex justify-content-center align-items-center w-100"
        >
          Filtrar
        </Button>
      </S.TransactionsModalFooter>
    </Modal>
  );
};
