import React from 'react';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Col, Row } from 'reactstrap';
import { IBrokerAccountFormProps } from '../../types';

export const BrokerAccountForm = ({
  loading = false,
  registrationError,
  showAlert,
  type = 'create',
  validation,
}: IBrokerAccountFormProps) => {
  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Nome da conta</Label>
        <Col lg={10}>
          <Input
            name="accountAlias"
            className="form-control"
            placeholder="Digite um nome da conta"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.accountAlias || ''}
            invalid={!!(validation.touched.accountAlias && validation.errors.accountAlias)}
          />
          {validation.touched.accountAlias && validation.errors.accountAlias ? (
            <FormFeedback type="invalid">{validation.errors.accountAlias}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Nome da corretora</Label>
        <Col lg={10}>
          <Input
            name="brokerName"
            type="text"
            placeholder="Digite um nome da corretora"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.brokerName || ''}
            invalid={!!(validation.touched.brokerName && validation.errors.brokerName)}
          />
          {validation.touched.brokerName && validation.errors.brokerName ? (
            <FormFeedback type="invalid">{validation.errors.brokerName}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'}`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
