import React from 'react';
import { Alert, Label, Form, FormFeedback, FormGroup, Col, Row, InputGroup } from 'reactstrap';
import { IDailyOperationFormProps } from '../../types';
import { NumericFormat } from 'react-number-format';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import classNames from 'classnames';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import financialMarketInstance from '@helpers/request/financialMarket';
import { IBrokerAccount } from '@modules/BrokerAccounts/types';
import { AUTH_COOKIE_NAME } from '@helpers/constants';
import { getCookie } from '@helpers/cookies';

export const DailyOperationCloseForm = ({
  loading = false,
  registrationError,
  showAlert,
  type = 'create',
  validation,
}: IDailyOperationFormProps) => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);

  const handleBrokerAccount = (data: { value: string; label: string }) => {
    validation.setFieldValue('brokerAccount', data);
  };

  function handleFinalAmount(data: any) {
    validation.setFieldValue('finalAmount', data.value);
  }

  function handleFinalDayTradeAmount(data: any) {
    validation.setFieldValue('finalDayTradeAmount', data.value);
  }

  function handleFinalSwingTradeAmount(data: any) {
    validation.setFieldValue('finalSwingTradeAmount', data.value);
  }

  const getBrokerAccounts = (_: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      financialMarketInstance
        .get('/broker-accounts?page=1&limit=100', {
          headers: {
            authorization: `Bearer ${getCookie(AUTH_COOKIE_NAME)}`,
          },
        })
        .then((response) => {
          const data = response.data.items.map((brokerAccount: IBrokerAccount) => ({
            value: brokerAccount.id,
            label: brokerAccount.accountAlias,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-1">
        <Label className="col-form-label col-lg-12">Conta corretora</Label>
        <Col lg={12}>
          <AsyncSelect
            name="brokerAccountId"
            className="react-select-container"
            classNames={{
              singleValue: () => 'react-select-single-value',
              placeholder: () => 'react-select-placeholder',
            }}
            aria-placeholder="Selecione uma conta de corretora..."
            cacheOptions
            defaultOptions
            loadOptions={getBrokerAccounts}
            onChange={handleBrokerAccount}
            value={validation.values.brokerAccountId}
            theme={(theme) => {
              if (layoutModeTypes !== 'dark') {
                return theme;
              }

              return {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#272727',
                  primary: '#272727',
                  neutral0: '#1E1E1E',
                },
              };
            }}
          />
          {validation.touched.initialAmount && validation.errors.initialAmount ? (
            <FormFeedback type="invalid">{validation.errors.initialAmount}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-1">
        <Label className="col-form-label col-lg-12">Valor final</Label>
        <Col lg={12}>
          <InputGroup>
            <NumericFormat
              id="finalAmount"
              name="finalAmount"
              className="form-control"
              decimalSeparator=","
              thousandSeparator="."
              placeholder="Digite um valor"
              prefix="R$ "
              value={validation.values.finalAmount}
              decimalScale={2}
              onValueChange={handleFinalAmount}
            />
          </InputGroup>
          {validation.touched.finalAmount && validation.errors.finalAmount ? (
            <FormFeedback type="invalid">{validation.errors.finalAmount}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-1">
        <Label className="col-form-label col-lg-12">Valor final Day Trade</Label>
        <Col lg={12}>
          <InputGroup>
            <NumericFormat
              id="finalDayTradeAmount"
              name="finalDayTradeAmount"
              className="form-control"
              decimalSeparator=","
              thousandSeparator="."
              placeholder="Digite um valor"
              prefix="R$ "
              value={validation.values.finalDayTradeAmount}
              decimalScale={2}
              onValueChange={handleFinalDayTradeAmount}
            />
          </InputGroup>
          {validation.touched.finalDayTradeAmount && validation.errors.finalDayTradeAmount ? (
            <FormFeedback type="invalid">{validation.errors.finalDayTradeAmount}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-1">
        <Label className="col-form-label col-lg-12">Valor final Swing Trade</Label>
        <Col lg={12}>
          <InputGroup>
            <NumericFormat
              id="finalSwingTradeAmount"
              name="finalSwingTradeAmount"
              className="form-control"
              decimalSeparator=","
              thousandSeparator="."
              placeholder="Digite um valor"
              prefix="R$ "
              value={validation.values.finalSwingTradeAmount}
              decimalScale={2}
              onValueChange={handleFinalSwingTradeAmount}
            />
          </InputGroup>
          {validation.touched.finalSwingTradeAmount && validation.errors.finalSwingTradeAmount ? (
            <FormFeedback type="invalid">{validation.errors.finalSwingTradeAmount}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-1">
        <Label className="col-form-label col-lg-12">Data de fechamento</Label>
        <Col lg={12}>
          <Flatpickr
            name="closedAt"
            className={classNames(`form-control d-block`, {
              'is-invalid': !!(validation.touched.closedAt && validation.errors.closedAt),
            })}
            placeholder="Selecione uma data"
            onChange={(date: any) =>
              validation.setFieldValue('closedAt', moment(date[0]).format('YYYY-MM-DD HH:mm:ss'))
            }
            value={validation.values.closedAt || new Date()}
            options={{
              altInput: true,
              altFormat: 'd/m/Y',
              dateFormat: 'Y-m-d',
              locale: Portuguese,
            }}
          />
          {validation.touched.closedAt && validation.errors.closedAt ? (
            <FormFeedback type="invalid">{validation.errors.closedAt}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={12} className="d-flex justify-content-end">
          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
            {loading ? <>Fechando...</> : 'Fechar operação'}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
