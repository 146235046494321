import React, { useEffect, useState } from 'react';
import { Column } from '@tanstack/react-table';
import classNames from 'classnames';
import moment from 'moment';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';

import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Flatpickr from 'react-flatpickr';

export const THeadFilter = ({
  column,
  setFilterTypes,
}: {
  column: Column<any, any>;
  setFilterTypes: (data: Record<string, any>) => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [type, setType] = useState('');
  const [destroyedComponent, setDestroyedComponent] = useState(false);
  const { disableFilter, filterType, filterOptions, disableFilterType, isJoinColumn, filterAlias } =
    (column.columnDef?.meta as Record<string, any>) ?? {};

  const handleClick = (value: string) => {
    setType(value);

    setFilterTypes((filterTypes: Record<string, any>) => {
      return {
        ...filterTypes,
        [column.id]: {
          type: value,
          isJoinColumn: isJoinColumn ?? false,
          filterAlias,
        },
      };
    });
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (filterType === 'select') {
      setFilterTypes((filterTypes: Record<string, any>) => {
        setType('eq');

        return {
          ...filterTypes,
          [column.id]: {
            type: 'eq',
            isJoinColumn,
            filterAlias,
          },
        };
      });
    }

    if (filterType === 'date_range') {
      setFilterTypes((filterTypes: Record<string, any>) => {
        setType('between');

        return {
          ...filterTypes,
          [column.id]: {
            type: 'between',
            isJoinColumn,
            filterAlias,
          },
        };
      });
    }

    if (filterType === 'text' || !filterType) {
      setFilterTypes((filterTypes: Record<string, any>) => {
        if (!disableFilterType) {
          setType('like');
        }

        return {
          ...filterTypes,
          [column.id]: {
            type: !disableFilterType ? 'like' : '',
            isJoinColumn,
            filterAlias,
          },
        };
      });
    }
  }, []);

  const [alreadyLoad, setAlreadyLoad] = useState(false);
  const [rangeDate, setDateRange] = useState<any>([]);

  useEffect(() => {
    const dates = column.getFilterValue() ? (column.getFilterValue() as string).split(';') : [];

    if (filterType === 'date_range' && dates.length >= 2 && !alreadyLoad) {
      setDateRange([moment(dates[0]).format('YYYY-MM-DD'), moment(dates[1]).format('YYYY-MM-DD')]);

      setAlreadyLoad(true);
    }

    if (filterType === 'date_range' && !column.getFilterValue()) {
      setDestroyedComponent(true);
      setDateRange([]);

      setTimeout(() => {
        setDestroyedComponent(false);
      }, 1);
    }
  }, [column.getFilterValue()]);

  return (
    <div className="mt-2" style={{ height: 40 }}>
      {!disableFilter && (
        <div className="position-relative d-flex direction-row">
          {filterType === 'select' && (
            <Input
              type="select"
              placeholder="Filtrar..."
              value={(column.getFilterValue() as string) ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                column.setFilterValue(e.target.value);
              }}
            >
              {filterOptions?.map((option: { value: string; label: string }) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          )}
          {filterType === 'text' ||
            (!filterType && (
              <Input
                type="text"
                placeholder="Filtrar..."
                value={(column.getFilterValue() as string) ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  column.setFilterValue(e.target.value);
                }}
              />
            ))}
          {filterType === 'date' && (
            <Flatpickr
              className={classNames('form-control d-block')}
              placeholder="Selecione uma data"
              onChange={(e) => {
                column.setFilterValue(moment(e[0]).format('YYYY-MM-DD'));
              }}
              value={
                column.getFilterValue()
                  ? moment(column.getFilterValue() as string).format('YYYY-MM-DD')
                  : ''
              }
              options={{
                altInput: true,
                altFormat: 'd/m/Y',
                dateFormat: 'Y-m-d',
                locale: Portuguese,
              }}
            />
          )}
          {filterType === 'date_range' && !destroyedComponent && (
            <Flatpickr
              className={classNames('form-control d-block')}
              placeholder="Selecione uma data"
              onChange={(e) => {
                if (e.length > 1) {
                  const date =
                    moment(e[0]).format('YYYY-MM-DD') + ';' + moment(e[1]).format('YYYY-MM-DD');
                  column.setFilterValue(date);
                }
              }}
              value={rangeDate}
              options={{
                mode: 'range',
                altInput: true,
                altFormat: 'd/m/Y',
                dateFormat: 'Y-m-d',
                locale: Portuguese,
              }}
            />
          )}
          {!disableFilterType && filterType !== 'select' && (
            <div
              className="position-absolute d-flex align-items-center"
              style={{ height: 36, right: 0 }}
            >
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
                className="pe-2"
              >
                <DropdownToggle
                  color="light"
                  size="md"
                  className="pe-0 ps-0 table-component-filter"
                  style={{ background: '#1E1E1E', border: 'none' }}
                >
                  <i className="mdi mdi-filter-variant me-0"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem active={type === 'eq'} onClick={() => handleClick('eq')}>
                    Igual a
                  </DropdownItem>
                  <DropdownItem active={type === 'like'} onClick={() => handleClick('like')}>
                    Contém
                  </DropdownItem>
                  <DropdownItem active={type === 'gt'} onClick={() => handleClick('gt')}>
                    Maior que
                  </DropdownItem>
                  <DropdownItem active={type === 'gte'} onClick={() => handleClick('gte')}>
                    Maior ou igual a
                  </DropdownItem>
                  <DropdownItem active={type === 'lt'} onClick={() => handleClick('lt')}>
                    Menor que
                  </DropdownItem>
                  <DropdownItem active={type === 'lte'} onClick={() => handleClick('lte')}>
                    Menor ou igual a
                  </DropdownItem>
                  <DropdownItem
                    active={type === 'startwith'}
                    onClick={() => handleClick('startswith')}
                  >
                    Começa com
                  </DropdownItem>
                  <DropdownItem
                    active={type === 'endswith'}
                    onClick={() => handleClick('endswith')}
                  >
                    Termina com
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
