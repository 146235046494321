import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';

import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import AsyncSelect from 'react-select/async';
import ReactEcharts from 'echarts-for-react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { getDailyEarnings } from '../services';
import financialMarketInstance from '@helpers/request/financialMarket';
import { AUTH_COOKIE_NAME } from '@helpers/constants';
import { getCookie } from '@helpers/cookies';
import { IBrokerAccount } from '@modules/BrokerAccounts/types';

import { options } from '../components/DailyEarnings';
import { IFinancePagination } from '@apptypes/pagination';
import { DailyEarnings as IDailyEarnings } from '../types';

const DailyEarnings = () => {
  const { layoutModeTypes } = useSelector((state: any) => state.Layout);
  const [items, setItems] = useState<IDailyEarnings[]>([]);
  const { mutate, isPending } = useMutation({
    mutationFn: ({
      brokerAccountId,
      initialDate,
      finalDate,
    }: {
      brokerAccountId: string;
      initialDate: Date;
      finalDate: Date;
    }) => getDailyEarnings({ brokerAccountId, initialDate, finalDate }),
    onSuccess: (data: IFinancePagination<IDailyEarnings>) => {
      setItems(data.items);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      brokerAccount: {
        value: '',
        label: '',
      },
      dates: [],
    },
    onSubmit: async (payload: any) =>
      mutate({
        brokerAccountId: payload.brokerAccount.value,
        initialDate: payload.dates[0],
        finalDate: payload.dates[1],
      }),
  });

  const handleBrokerAccount = (data: { value: string; label: string }) => {
    validation.setFieldValue('brokerAccount', data);
  };

  const getBrokerAccounts = (_: string, callback: (data: any) => void) => {
    new Promise((resolve, reject) => {
      financialMarketInstance
        .get('/broker-accounts?page=1&limit=100', {
          headers: {
            authorization: `Bearer ${getCookie(AUTH_COOKIE_NAME)}`,
          },
        })
        .then((response) => {
          const data = response.data.items.map((brokerAccount: IBrokerAccount) => ({
            value: brokerAccount.id,
            label: brokerAccount.accountAlias,
          }));

          callback(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatórios',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de ganhos diários',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle>Relatório de ganhos diários</CardTitle>

              <Form
                className=""
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <FormGroup className="col-md-2">
                    <Label className="col-form-label col-lg-12">Conta da corretora</Label>
                    <Col lg={12}>
                      <AsyncSelect
                        name="brokerAccountId"
                        className="react-select-container"
                        classNames={{
                          singleValue: () => 'react-select-single-value',
                          placeholder: () => 'react-select-placeholder',
                        }}
                        aria-placeholder="Selecione uma conta de corretora..."
                        cacheOptions
                        defaultOptions
                        loadOptions={getBrokerAccounts}
                        onChange={handleBrokerAccount}
                        value={validation.values.brokerAccountId}
                        theme={(theme) => {
                          if (layoutModeTypes !== 'dark') {
                            return theme;
                          }

                          return {
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: '#272727',
                              primary: '#272727',
                              neutral0: '#1E1E1E',
                            },
                          };
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-2 pe-0">
                    <Label className="col-form-label me-2">Selecione um período</Label>
                    <Col lg={12}>
                      <InputGroup style={{ width: '200px', maxWidth: '200px' }}>
                        <Flatpickr
                          name="dates"
                          className="form-control d-block"
                          placeholder="Selecione uma data"
                          onChange={(dates) => {
                            validation.setFieldValue('dates', dates);
                          }}
                          options={{
                            mode: 'range',
                            altInput: true,
                            altFormat: 'd/m/Y',
                            dateFormat: 'Y-m-d',
                            defaultDate: [
                              moment(validation.values.dates[0]).format('YYYY-MM-DD'),
                              moment(validation.values.dates[1]).format('YYYY-MM-DD'),
                            ],
                            locale: Portuguese,
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="col-md-2 d-flex justify-content-start align-items-end ps-0">
                    <Col lg={12} className="d-flex justify-content-start align-items-end">
                      <Button color="primary" type="submit" disabled={isPending}>
                        Pesquisar
                      </Button>
                    </Col>
                  </FormGroup>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {items.length ? (
            <ReactEcharts style={{ height: '500px' }} option={options({ items })} />
          ) : (
            <Skeleton height={500} />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(DailyEarnings);
