import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { BrokerAccountForm } from '@modules/BrokerAccounts/components';
import { getByID, update } from '@modules/BrokerAccounts/services';
import { IBrokerAccountUpdate } from '../types';
import { TYPE_ACCOUNT_ALIAS, TYPE_BROKER_NAME } from '../constants';
import { useSelector } from 'react-redux';

const BrokerAccountUpdate = ({ router }: IWithRouter) => {
  const { brokerAccountId } = useParams();
  const { user }: any = useSelector((state: any) => state.Profile);

  const { data, isLoading } = useQuery({
    queryKey: ['getBrokerAccountById', brokerAccountId],
    queryFn: () => getByID(brokerAccountId || ''),
    retry: 1,
  });

  const { error, isSuccess, mutate } = useMutation({
    mutationFn: (payload: IBrokerAccountUpdate) => {
      if (brokerAccountId) {
        return update(brokerAccountId, user.id, payload);
      }

      return Promise.reject();
    },
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/broker-accounts');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountAlias: data?.accountAlias || '',
      brokerName: data?.brokerName || '',
    },
    validationSchema: Yup.object({
      accountAlias: Yup.string().required(TYPE_ACCOUNT_ALIAS),
      brokerName: Yup.string().required(TYPE_BROKER_NAME),
    }),
    onSubmit: async (payload: IBrokerAccountUpdate) => mutate(payload),
  });

  return (
    <Page
      title="Editar contas corretoras"
      breadcrumb={{
        title: 'Editar contas corretoras',
        titleHref: '/broker-accounts',
        breadcrumbItem: 'Listar contas corretoras',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar conta do corretor</CardTitle>
              <div className="p-2 mt-3">
                {isLoading ? (
                  <Spinners />
                ) : (
                  <BrokerAccountForm
                    loading={isLoading}
                    validation={validation}
                    registrationError={error?.message}
                    showAlert={isSuccess}
                    type="edit"
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(BrokerAccountUpdate);
