import React, { useState } from 'react';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';
import moment from 'moment';
import { IDailyOperationOpening } from '@modules/DailyOperations/types';

const TRow = ({ row, dragActiveColumn, dragOver }: any) => {
  const [isExpansible, setIsExpansible] = useState(false);

  const openings = row.original.openings || [];

  return (
    <>
      <tr key={row.id}>
        {row.getVisibleCells().map((cell, index) => (
          <td
            className={classNames(`${dragActiveColumn} ${index}`, {
              'table-draggable-active_tbody': dragActiveColumn === index,
              'table-draggle-hover': dragOver === index,
              'd-flex': index === row.getVisibleCells().length - 1,
            })}
            colSpan={cell.colSpan}
            key={cell.id}
          >
            {index === 0 && openings.length > 0 ? (
              <div className="d-flex align-items-center justify-content-between">
                <Button size="sm" color="none" onClick={() => setIsExpansible(!isExpansible)}>
                  <i
                    className={classNames('mdi me-0 fw-bold', {
                      'mdi-chevron-up': isExpansible,
                      'mdi-chevron-down': !isExpansible,
                    })}
                  ></i>
                </Button>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ) : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </td>
        ))}
      </tr>
      {isExpansible && (
        <>
          <tr className="table-expansible-row">
            <td colSpan={row.getVisibleCells().length}>
              <h2 className="font-size-16 text-white mb-0 p-0 ps-1 pt-1 fw-bold">Aberturas</h2>
            </td>
          </tr>
          {openings.map((opening: IDailyOperationOpening) => (
            <tr key={opening.id} className="table-expansible-row">
              <td colSpan={row.getVisibleCells().length} key={opening.id}>
                <div className="d-flex align-items-center ps-1 pe-1 pb-1 ms-0">
                  <h5 className="font-size-14 ms-0 mb-0 text-white">
                    <strong>Valor</strong>:{' '}
                    <span className="text-success">{moneyTransform(opening.amount)}</span>
                  </h5>
                  <h5 className="font-size-14 ms-2 mb-0 text-white">
                    <strong>Data abertura</strong>:{' '}
                    {moment(opening.openedAt).format('DD/MM/YYYY HH:mm:ss')}
                  </h5>
                  <h5 className="font-size-14 ms-2 mb-0 text-white">
                    <strong>Data fechamento</strong>:{' '}
                    {moment(opening.closedAt).format('DD/MM/YYYY HH:mm:ss')}
                  </h5>
                </div>
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  );
};

export default TRow;
