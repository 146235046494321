import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { DailyOperationCloseForm } from '../DailyOperationCloseForm';
import { useMutation } from '@tanstack/react-query';
import { IDailyOperationClose } from '@modules/DailyOperations/types';
import { close } from '@modules/DailyOperations/services';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  TYPE_CLOSED_AT,
  TYPE_FINAL_AMOUNT,
  TYPE_FINAL_DAY_TRADE_AMOUNT,
  TYPE_FINAL_SWING_TRADE_AMOUNT,
} from '@modules/DailyOperations/constants';

const CloseContent = ({ handleModal }: any) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IDailyOperationClose) => close(payload),
    onSuccess: () => {
      handleModal(false);
      validation.resetForm();
    },
  });

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      brokerAccount: {
        value: '',
        label: '',
      },
      finalAmount: 0,
      finalDayTradeAmount: 0,
      finalSwingTradeAmount: 0,
      closedAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    },
    validationSchema: Yup.object({
      finalAmount: Yup.string().required(TYPE_FINAL_AMOUNT),
      finalDayTradeAmount: Yup.string().required(TYPE_FINAL_DAY_TRADE_AMOUNT),
      finalSwingTradeAmount: Yup.string().required(TYPE_FINAL_SWING_TRADE_AMOUNT),
      closedAt: Yup.string().required(TYPE_CLOSED_AT),
    }),
    onSubmit: (payload: IDailyOperationClose) => mutate(payload),
  });

  return (
    <>
      <ModalHeader>Fechar operação</ModalHeader>
      <ModalBody>
        <DailyOperationCloseForm
          validation={validation}
          loading={isPending}
          registrationError={error?.message}
          showAlert={isSuccess}
        />
      </ModalBody>
    </>
  );
};

export default CloseContent;
