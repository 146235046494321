import styled from 'styled-components';

import { Input } from 'reactstrap';

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 16px;

  width: 100%;
  max-width: 35%;

  background: ${({ theme }) => theme.customWhite};
  border-radius: 0.25rem;
`;

export const DateNavigationContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DateDisplayButton = styled.button`
  background: none;
  border: none;
  border-radius: 0.25rem;

  font-size: 14px;
  color: ${({ theme }) => theme.gray700};

  padding: 8px 16px;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.gray300};
  }
`;

export const NavigationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: none;
  border-radius: 0.25rem;

  width: 32px;
  height: 32px;

  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.gray300};
  }
`;

export const NavigationIcon = styled.i`
  color: ${({ theme }) => theme.gray600};
`;

export const QuickActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;

  width: 100%;
`;

export const TodayButton = styled.button<{ isActive?: boolean }>`
  background: ${({ theme, isActive }) => (isActive ? theme.primary : theme.gray300)};
  color: ${({ theme, isActive }) => (isActive ? theme.bodyBg : theme.gray700)};

  border: none;
  border-radius: 0.25rem;

  padding: 8px 16px;

  width: 50%;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.97);
  }
`;

export const PeriodSelector = styled(Input)`
  background: ${({ theme }) => theme.gray300};

  border: none;
  border-radius: 0.25rem;

  padding: 8px 16px;

  width: 50%;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.97);
  }

  &.form-select {
    background-image: none;
  }
`;

export const PeriodOption = styled.option`
  color: ${({ theme }) => theme.gray700};
  text-align: center;
`;

export const CalendarDropdown = styled.div`
  position: absolute;
  top: 110%;
  left: 0;

  z-index: 10;

  width: 100%;

  .react-calendar {
    background-color: ${({ theme }) => theme.customWhite};
    color: ${({ theme }) => theme.gray700};
  }

  .react-calendar button {
    color: ${({ theme }) => theme.gray600};
  }

  button.react-calendar__tile--active {
    color: ${({ theme }) => theme.customWhite};
  }

  .react-calendar__tile:hover {
    background: ${({ theme }) => theme.gray300};
  }

  button.react-calendar__tile--active:enabled:hover {
    background: ${({ theme }) => theme.primary};
  }

  .react-calendar__navigation button:hover {
    background: ${({ theme }) => theme.gray300};
    color: ${({ theme }) => theme.gray600};
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 32px;
`;

export const FilterButton = styled.a`
  background: none;
  border: none;
  text-decoration: underline !important;
  color: ${({ theme }) => theme.primary};

  font-size: 14px;

  &:hover {
    filter: brightness(0.7);
  }
`;
