import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { Badge } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';

export const buildColumns = () => {
  const columnHelper = createColumnHelper();

  const status = {
    opened: 'Aberto',
    closed: 'Fechado',
  };

  return [
    columnHelper.accessor('undefined', {
      cell: () => <></>,
      header: '',
      size: 1,
    }),
    columnHelper.accessor('status', {
      cell: ({ row, getValue }: any) => (
        <Badge color={getValue() === 'opened' ? 'success' : 'danger'} className="font-size-12" pill>
          {status[getValue()]}
        </Badge>
      ),
      header: 'Status',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'opened', label: 'Aberto' },
          { value: 'closed', label: 'Fechado' },
        ],
      },
    }),
    columnHelper.accessor('initialAmount', {
      cell: ({ getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Valor inicial',
      size: 15,
    }),
    columnHelper.accessor('brokerAccount.accountAlias', {
      cell: ({ getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Conta corretora',
      size: 15,
    }),
    columnHelper.accessor('finalAmount', {
      cell: ({ getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue() || 0)}</h5>
      ),
      header: 'Valor final',
      size: 15,
    }),
    columnHelper.accessor('finalSwingTradeAmount', {
      cell: ({ getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue() || 0)}</h5>
      ),
      header: 'Valor Swing Trade',
      size: 15,
    }),
    columnHelper.accessor('finalDayTradeAmount', {
      cell: ({ getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue() || 0)}</h5>
      ),
      header: 'Valor Day Trade',
      size: 15,
    }),
    columnHelper.accessor('openedByUser.name', {
      cell: ({ getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Aberto por',
      size: 15,
    }),
    columnHelper.accessor('closedByUser.name', {
      cell: ({ getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Fechado por',
      size: 15,
    }),
    columnHelper.accessor('modifiedByUser.name', {
      cell: ({ getValue }: any) => <h5 className="font-size-14 mb-1">{getValue()}</h5>,
      header: 'Modificado por',
      size: 15,
    }),
    columnHelper.accessor('openedAt', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moment(getValue()).format('DD/MM/YYYY HH:mm:ss')}</h5>
      ),
      header: 'Data de abertura',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updatedAt', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moment(getValue()).format('DD/MM/YYYY HH:mm:ss')}</h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
  ];
};
