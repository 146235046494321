import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IWithRouter } from '@apptypes/WithRouter';
import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { create } from '@modules/BrokerAccounts/services';
import { BrokerAccountForm } from '@modules/BrokerAccounts/components';
import { IBrokerAccount, IBrokerAccountCreate } from '../types';
import { TYPE_ACCOUNT_ALIAS, TYPE_BROKER_NAME } from '../constants';

const BrokerAccountCreate = ({ router }: IWithRouter) => {
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IBrokerAccountCreate) => create(payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/broker-accounts');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountAlias: '',
      brokerName: '',
    },
    validationSchema: Yup.object({
      accountAlias: Yup.string().required(TYPE_ACCOUNT_ALIAS),
      brokerName: Yup.string().required(TYPE_BROKER_NAME),
    }),
    onSubmit: (payload: IBrokerAccount) => mutate(payload),
  });

  return (
    <Page
      title="Criar contas corretoras"
      breadcrumb={{
        title: 'Criar contas corretoras',
        titleHref: '/broker-accounts',
        breadcrumbItem: 'Listar contas corretoras',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar nova conta de corretor</CardTitle>
              <div className="p-2 mt-3">
                <BrokerAccountForm
                  validation={validation}
                  loading={isPending}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(BrokerAccountCreate);
