import { moneyTransform } from '@helpers/transforms/money';
import { DailyEarnings } from '../types';

interface IOptions {
  items: DailyEarnings[];
}

export const options = ({ items }: IOptions) => {
  const dates = items.map((item: DailyEarnings) => item.referenceDate);

  const dayTradeAmount = {
    name: 'DayTrade',
    type: 'line',
    stack: 'Total',
    data: items.map((item: DailyEarnings) => item.details.dayTradeAmount),
  };

  const swingTradeAmount = {
    name: 'SwingTrade',
    type: 'line',
    stack: 'Total',
    data: items.map((item: DailyEarnings) => item.details.swingTradeAmount),
  };

  // const totalAmount = {
  //   name: 'Total',
  //   type: 'line',
  //   stack: 'Total',
  //   data: items.map((item: DailyEarnings) => item.details.swingTradeAmount + item.details.dayTradeAmount)
  // };

  return {
    title: {
      text: 'Ganhos diários',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const items = params.map(
          (item: any) =>
            `<div style="color: ${item.color};">
              <strong>${item.seriesName}</strong>: ${moneyTransform(item.value)}
            </div>`,
        );

        const total = params.reduce((acc: any, item: any) => acc + item.value, 0);

        items.push(`<div style="color: black;">
              <strong>Total</strong>: ${moneyTransform(total)}
            </div>`);

        return items.join('');
      },
    },
    legend: {
      data: ['DayTrade', 'SwingTrade', 'Total'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dates,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => moneyTransform(value),
      },
    },
    series: [dayTradeAmount, swingTradeAmount],
  };
};
