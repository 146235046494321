import { InstanceError } from '@apptypes/instance';
import { UNEXPECTED_ERROR } from '@helpers/constants';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/financialMarket';
import { IBrokerAccount, IBrokerAccountCreate, IBrokerAccountUpdate } from '../types';
import { IFinancePagination } from '@apptypes/pagination';
export const getByID = async (brokerAccountId: string): Promise<IBrokerAccount> => {
  try {
    const { data } = await instance.get(`/broker-accounts/${brokerAccountId}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Conta de corretor não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para visualizar a conta do corretor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAll = async ({
  page,
  limit,
  filters = '',
}: {
  page: number;
  limit: number;
  filters: string;
}): Promise<IFinancePagination<IBrokerAccount>> => {
  try {
    const { data } = await instance.get(
      `/broker-accounts?page=${page}&limit=${limit}${filters ? `&filters=${filters}` : ''}`,
    );
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Conta de corretor não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: IBrokerAccountCreate): Promise<IBrokerAccount> => {
  try {
    const { data } = await instance.post('/broker-accounts', payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar a conta do corretor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: UNEXPECTED_ERROR,
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  brokerAccountId: string,
  userId: string,
  payload: IBrokerAccountUpdate,
): Promise<IBrokerAccount> => {
  try {
    const { data } = await instance.put(`/broker-accounts/${brokerAccountId}`, {
      ...payload,
      modifiedByUserId: userId,
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar a conta do corretor',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Conta do corretor não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar a conta do corretor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (brokerAccountId: string) => {
  try {
    const { data } = await instance.delete(`/broker-accounts/${brokerAccountId}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar a conta do corretor',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para deletar a conta do corretor',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
