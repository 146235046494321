import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Card, CardBody, Col, Row } from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';

import { TransactionTypeEnum } from '@modules/Finance/ultis/transactions/enums';

import { Tabs } from '@modules/Finance/components/Tabs';

import { useTransactions } from '@modules/Finance/contexts/TransactionsContext';

import { SummaryFinanceCard } from './components/SummaryFinanceCard';
import { CalendarPicker } from './components/CalendarPicker';
import { ManageTransactionsModal } from './components/ManageTransactionsModal';
import { TransactionsFilterModal } from './components/TransactionsFilterModal';
import { TransactionList } from './components/TransactionsList';

import * as S from './styles';

const Transactions = () => {
  // * [Hooks] * //
  const theme = useTheme();

  // * [Contexts] * //
  const {
    expenses,
    transactionActionType,
    //
    showManageTrasactionsModal,
    //
    setTransactionActionType,
    //
    handleToggleManageTransactionsModal,
    handleGetAllExpenses,
  } = useTransactions();

  // * [States] * //
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  // * [Obejcts] * //
  const TAB_OPTIONS = [
    {
      label: 'A pagar',
      value: TransactionTypeEnum.Expense,
    },
    {
      label: 'A receber',
      value: TransactionTypeEnum.Income,
    },
  ];

  // * [Effects] * //
  useEffect(() => {
    handleGetAllExpenses();
  }, [showManageTrasactionsModal]);

  return (
    <Page
      title="A pagar e receber"
      breadcrumb={{
        title: 'Movimentações',
        titleHref: '/finance-transactions',
        breadcrumbItem: 'A pagar e receber',
      }}
    >
      <Row>
        <Col lg="12">
          <S.Header>
            <SummaryFinanceCard
              name="Receita"
              icon="fas fa-arrow-up"
              value="R$ 0,00"
              color={theme.success}
            />

            <SummaryFinanceCard
              name="Despesa"
              icon="fas fa-arrow-down"
              value="R$ 0,00"
              color={theme.danger}
            />

            <SummaryFinanceCard
              name="Resultado"
              icon="mdi mdi-currency-usd"
              value="R$ 0,00"
              color={theme.info}
            />

            <CalendarPicker />
          </S.Header>

          <Card>
            <CardBody>
              <S.Toolbar>
                <S.FilterButton onClick={() => setShowFiltersModal(true)}>Filtrar</S.FilterButton>
                <S.ActionButton onClick={handleToggleManageTransactionsModal}>
                  Adicionar Transação
                </S.ActionButton>
              </S.Toolbar>

              <Tabs
                activeTab={transactionActionType}
                toggleTab={setTransactionActionType}
                tabs={TAB_OPTIONS}
              />

              {transactionActionType === TransactionTypeEnum.Expense && (
                <TransactionList transactions={expenses?.expenses} />
              )}

              {transactionActionType === TransactionTypeEnum.Income && (
                <TransactionList transactions={[]} />
              )}
            </CardBody>
          </Card>

          {showFiltersModal && (
            <TransactionsFilterModal
              showModal={showFiltersModal}
              toggle={() => setShowFiltersModal((prev) => !prev)}
              title="Filtrar por:"
            />
          )}

          {showManageTrasactionsModal && (
            <ManageTransactionsModal
              title={'Adicionar Transação'}
              showModal={showManageTrasactionsModal}
              type={transactionActionType}
              toggle={handleToggleManageTransactionsModal}
            />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Transactions);
