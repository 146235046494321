import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import useDataFetch from '@modules/Finance/hooks/useDataFetch';
import { getAllExpenses } from '@modules/Finance/services/transactions';

import { TransactionTypeEnum } from '@modules/Finance/ultis/transactions/enums';

import { IExpenseData } from '@modules/Finance/types/transactions';

import { TransactionsContextType, TransactionsProviderProps } from './types';

export const TransactionsContext = createContext<TransactionsContextType | undefined>(undefined);

export default function TransactionsProvider({ children }: TransactionsProviderProps) {
  // * [Datas] * //
  const {
    data: expenses,
    setData: setExpenses,
    loading: loadingExpenses,
    setLoading: setLoadingExpenses,
  } = useDataFetch<IExpenseData | null>(null);

  // * [States] * //
  const [showManageTrasactionsModal, setShowManageTransactionsModal] = useState<boolean>(false);
  const [transactionActionType, setTransactionActionType] = useState<TransactionTypeEnum>(
    TransactionTypeEnum.Expense,
  );

  // * [Functions] * //
  const handleToggleManageTransactionsModal = () => {
    setShowManageTransactionsModal((prev) => !prev);
    setExpenses(null);
  };

  const handleGetAllExpenses = async () => {
    setLoadingExpenses(true);

    try {
      const response = await getAllExpenses();

      setExpenses(response);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar despensas');
    } finally {
      setLoadingExpenses(false);
    }
  };

  return (
    <TransactionsContext.Provider
      value={{
        expenses,
        transactionActionType,
        //
        setTransactionActionType,
        //
        loadingExpenses,
        showManageTrasactionsModal,
        //
        handleGetAllExpenses,
        handleToggleManageTransactionsModal,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
}

export const useTransactions = () => {
  const context = useContext(TransactionsContext);

  if (!context) {
    throw new Error('useTransactions must be used within a TransactionsProvider');
  }

  return context;
};
