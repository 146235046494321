import React, { useCallback } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import * as S from './styles';

import { IManageTransactionsModalProps } from './types';
export const ManageTransactionsModal = ({
  showModal,
  toggle,
  title,
  type,
}: IManageTransactionsModalProps) => {
  // * [Functions] * //
  const toggleModal = useCallback(() => {
    toggle();
  }, []);

  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered>
      <S.ManageTransactionsModalHeader toggle={toggleModal}>
        {title}
      </S.ManageTransactionsModalHeader>

      <ModalBody></ModalBody>

      <S.ManageTransactionsModalFooter>
        <Button
          color="primary"
          onClick={() => {}}
          disabled={false}
          className="d-flex justify-content-center align-items-center"
        >
          Salvar
        </Button>
      </S.ManageTransactionsModalFooter>
    </Modal>
  );
};
