import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import TableContainer from '@common/NewTableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/BrokerAccounts/components/Table/constants';
import { deleteByID, getAll } from '@modules/BrokerAccounts/services';

const BrokerAccounts = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteBrokerAccountID, setDeleteBrokerAccountID] = useState<string | undefined>();

  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getAllBrokerAccounts', page, size, filters],
    queryFn: () => getAll({ page, limit: size, filters }),
    initialData: {
      page: {
        limit: 10,
        page: 1,
        totalRows: 0,
        totalPages: 0,
      },
      items: [],
    },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      if (deleteBrokerAccountID) {
        return deleteByID(deleteBrokerAccountID);
      }

      return Promise.resolve();
    },
    onSuccess: () => {
      toast.success('Conta corretora deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setSearchParams({ page: '1' });
      setDeleteModal(false);
      refetch();
    },
  });

  const handleDeleteBrokerAccountModal = (brokerAccountId: string) => {
    setDeleteBrokerAccountID(brokerAccountId);
    setDeleteModal(true);
  };

  return (
    <Page
      title="Contas corretoras"
      breadcrumb={{
        title: 'Contas corretoras',
        titleHref: '/broker-accounts',
        breadcrumbItem: 'Listar contas corretoras',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="broker-accounts"
                columns={buildColumns(user?.is_admin, handleDeleteBrokerAccountModal)}
                currentPage={page}
                customPageSize={size}
                data={data.items}
                loading={isFetching}
                totalItems={data.page.totalPages}
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/broker-accounts/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(BrokerAccounts);
